import { Component, OnInit, Input, ElementRef, ViewChild} from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { log } from 'console';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-job-card-details',
  templateUrl: './job-card-details.component.html',
  styleUrls: ['./job-card-details.component.scss']
})
export class JobCardDetailsComponent implements OnInit {

  @Input() partData: any;
  @Input() jobId: any;
  @Input() fetchData: any;
  @Input() selectedRateListAPI: any;
  selectedLabourRateList: any = null;
  selectedRateListId: number = null;
  selectedInsuranceType: any;
  editingQuantity: boolean[] = [];
  editingOdometerReading: boolean = false;
  editingSRType: boolean = false;
  quantity: number;
  parts_number: string;
  job_type: string;
  odometer_reading: number;
  sub: Subscription = new Subscription();
  jobCard:any = [];
  jobRejected: any;
  constructor(private modalService: NgbModal,private dashboard:DashboardService,) { }
  role: string;// To hold the selected insurance type
  originalCompanyName: string = '';
  originalInsuranceType: any;
  // Define the insurance types
  insuranceTypes = [
    { label: 'Comprehensive', value: 'Comprehensive' },
    { label: 'No Insurance', value: 'No Insurance' },
    { label: 'Third Party', value: 'Third Party' }
  ];

  ngOnInit(): void {
    this.originalCompanyName = this.partData.INSURENCE_COMPONY_NAME_s;
    this.selectedInsuranceType = this.partData.ASSET_INSURANCE_TYPE_s || null;
    this.originalInsuranceType = this.selectedInsuranceType;
    this.initializeTransactionDate();
    if (localStorage.getItem("userDetail")) {
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    }
    this.resetSelectedLabourRate();
    this.tempOdometerReading = this.partData.odometer_reading;
  }
  tempOdometerReading: number;
  startEdit() {
    // When starting to edit, store the original value
    this.tempOdometerReading = this.partData.odometer_reading;
    this.editingOdometerReading = true;
}
  cancelEdit() {
    // Reset the odometer reading to the original value
    this.tempOdometerReading = this.partData.odometer_reading;
    this.editingOdometerReading = false;
}

  saveSRType() {
    this.editingSRType = false;
  }

  validateInput(event: KeyboardEvent) {
    // Allow only numbers and restrict input to 6 digits
    const input = (event.target as HTMLInputElement).value;
    if (input.length >= 7 && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault(); // Prevent input if length is already 6
    }
}
  isEditMode: boolean = false; 
  showInsuranceTypeRow: boolean = true;
  toggleEditMode() {
    this.isEditMode = !this.isEditMode;

    // If entering edit mode, pre-fill selectedInsuranceType with the existing value
    if (this.isEditMode) {
      this.selectedInsuranceType = this.partData.ASSET_INSURANCE_TYPE_s;
    }
  }

  saveInsuranceType() {
    if (this.isEditMode) {
      this.partData.ASSET_INSURANCE_TYPE_s = this.selectedInsuranceType; // Update partData with new insurance type
      this.originalInsuranceType = this.selectedInsuranceType; // Update original value
      this.isEditMode = false; // Exit editing mode
    }
  }


  onSave(jobType: any, Quantity: any, PartNumber: any, odoMeter: any){
    const ListInput: any = {} as any;
    ListInput.job_id = this.jobId;
    ListInput.job_type= jobType;
    ListInput.odometer_reading= odoMeter;
    ListInput.quantity= Quantity;
    ListInput.parts_number= PartNumber;

    try {
      this.sub.add(this.dashboard.updateJob(ListInput).subscribe({
        next: (response) => {
          if (response) {
            this.jobCard = response;
            this.editingOdometerReading = false;
            this.partData.odometer_reading = odoMeter;
            this.editingSRType = false;
            Swal.fire({
              icon: 'success',
              title: 'Data Updated Successfully',
              text: 'The job details have been updated.',
              timer: 2000, 
              showConfirmButton: false
            });
              const jobInput: any = {} as any;
              jobInput.job_id = this.jobId;
              this.sub.add(this.dashboard.jobDetails(jobInput).subscribe({
              next: (response) => {
                if (response) {
                  this.partData.job_code = response.data.job_code;                  
                }
              },
            }));
          }else {
            this.jobCard = []
          }
        },
        error: (err) => {
          throw(err)
        }
      }))
    }
    catch(error){
      throw(error);
    }
  }
  resetSelectedLabourRate() {
    this.selectedLabourRateList = null;
  }
  selectedRateListName: string | undefined;

  onRateListChange() {
    const selectedRate = this.fetchData.find(rate => rate.ROW_ID === this.selectedRateListId);
    this.selectedRateListName = selectedRate ? selectedRate.NAME_s : undefined;
    this.selectedRateListAPI = this.selectedRateListName;
  }
  
  errorMessage: string = '';
  onApprove(jobType: any, Registration: any, Chassis: any, odoMeter: any, companyName: any, insuranceType: any, validdt: any){
  
    const ListInput: any = {} as any;
    ListInput.job_id = this.jobId;
    ListInput.jc_part_data = this.partData.part_details.map(part => ({
      QuantityRequested: part.quantity,
      PartNumber: part.part_number,
      Status: "External Shipped", 
      TMType: "Paid",                            
      TMAMCValidityFlag: "Y",              
      // TMCustomerAprvl: "Requested" 
    }));
    ListInput.SRType= jobType;
    ListInput.CurrentMileage= odoMeter;
    ListInput.Chassis_number = Chassis.toUpperCase();;
    ListInput.Registration_number = Registration;
    ListInput.Insurance_company_name =  this.originalCompanyName || this.insuranceCompanyName||"";
    ListInput.Asset_insurance_type =  this.originalInsuranceType || this.selectedInsuranceType||"";
    // ListInput.Asset_insurance_type = ""
    // console.log("Insurance Type", ListInput.Asset_insurance_type);
    
    ListInput.Asset_insurance_valid_date = this.formatTransactionDate(this.originalTransactionDate) || this.formatTransactionDate(this.transactionDate)||"";
    console.log("On approve date", ListInput.Asset_insurance_valid_date);
    
    // ListInput.Insurance_company_name = this.insuranceCompanyName || companyName ;
    // ListInput.Asset_insurance_type = this.selectedInsuranceType || insuranceType;
    // ListInput.Asset_insurance_type = "";
    // ListInput.Asset_insurance_valid_date = validdt;
    // ListInput.Description = "fitness work";
    // console.log(this.selectedLabourRateList?.ROW_ID);
    // console.log(validdt)
     
    if (this.selectedRateListId) {
      ListInput.Rate_list_id = this.selectedRateListId;
    } else {
      // Handle the case when no rate list is selected
      ListInput.Rate_list_id = null;
    }
    this.errorMessage = '';

  // Validate required fields
    // if (!ListInput.Insurance_company_name || !ListInput.Asset_insurance_type || !ListInput.Asset_insurance_valid_date) {
    //   // if (!ListInput.Insurance_company_name ||  !ListInput.Asset_insurance_valid_date) {
    //   this.errorMessage = 'Please fill in all mandatary fields before proceeding.';
      
    //   // Display error message for 1 second
    //   setTimeout(() => {
    //     this.errorMessage = '';
    //   }, 1500);
      
    //   return; // Prevent further execution
    // }
    try {
      this.sub.add(this.dashboard.srCreation(ListInput).subscribe({
        next: (response) => {
          if (response.success === true) {
            Swal.fire({
              icon: 'success',
              title: 'Data Updated Successfully',
              text: response.msg.msg,
              timer: 2000,
              showConfirmButton: false
            });
            setTimeout(() => {
              this.modalService.dismissAll();
            }, 2250);
            const jobInput: any = {} as any;
              jobInput.job_id = this.jobId;
              this.sub.add(this.dashboard.jobDetails(jobInput).subscribe({
              next: (response) => {
                if (response) {
                  this.jobRejected = response.data.jc_status ;
                  this.partData.rate_list = response.data.rate_list;
                  this.partData.ASSET_INSURANCE_TYPE_s = response.data.ASSET_INSURANCE_TYPE_s;
                  this.partData.ASSET_INSURANCE_VALID_DT_dt = response.data.ASSET_INSURANCE_VALID_DT_dt;
                  this.partData.INSURENCE_COMPONY_NAME_s= response.data.INSURENCE_COMPONY_NAME_s;
                  this.onRateListChange()
                  this.isEditingDate = false;
                  this.isEditing = false;
                }
                
              },
              error: (err) => {
                throw(err)
              }
            }));
          } else {
            
            Swal.fire({
              icon: 'error',
              title: 'Data not updated',
              text: response.msg.msg,
              timer: 2000, 
              showConfirmButton: false
            });
          }
        },
        error: (err) => {
          throw(err)
        }
      }))
    }
    catch(error){
      throw(error);
    }
  }
  close(){
    this.modalService.dismissAll();
    this.selectedLabourRateList = null;
  }

  reject(){
    Swal.fire({
      title: 'Reason to Reject', 
      input: 'text', 
      inputPlaceholder: 'Enter your reason here', 
      showCancelButton: true,
      confirmButtonText: 'Submit', 
      cancelButtonText: 'Cancel', 
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Please write the Reason!'
        } else if (value.length < 5) {
          return 'Reason must be at least 5 characters!'
        } else if (value.length > 100) {
          return 'Reason must be less than 100 characters!'
        }
        return null;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const ListInput: any = {} as any;
        ListInput.job_id = this.jobId;
        ListInput.parts_number = "";
        ListInput.job_type = "";
        ListInput.action_type = "Rejected";
        ListInput.reason_for_rejected = result.value;
  
        this.sub.add(this.dashboard.updateJob(ListInput).subscribe({
          next: (response) => {
            if (response) {
              Swal.fire(
                {icon: 'success',
                title: 'Success',
                text: 'Rejected successfully',
                timer: 2000,
                showConfirmButton: false
              });
              console.log('Reason submitted successfully');
  
              // Call the jobDetails API again to refresh the data
              const jobDetailsInput: any = {} as any;
              jobDetailsInput.job_id = this.jobId;
  
              this.sub.add(this.dashboard.jobDetails(jobDetailsInput).subscribe({
                next: (response) => {
                  if (response) {
                    this.jobRejected = response.data.jc_status;
                    this.partData.rate_list = response.data.rate_list
                  }
                },
                error: (err) => {
                  throw(err)
                }
              }));
            }
          },
          error: (err) => {
            throw(err)
          }
        }));
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log('User canceled the input');
      }
    });
  }

  @ViewChild('companyNameInput') companyNameInput!: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
      this.companyNameInput.nativeElement.addEventListener('input', (event) => {
          this.companyNameInput.nativeElement.value = this.companyNameInput.nativeElement.value.replace(/[^A-Za-z\s]/g, '');
      });
  }
    // Getter for Company Name
    get insuranceCompanyName(): string {
      return this.partData?.INSURANCE_COMPANY_NAME_s || '';
    }
  
    // Setter for Company Name
    set insuranceCompanyName(value: string) {
      if (this.partData) {
        this.partData.INSURANCE_COMPANY_NAME_s = value;
      }
    }
  
    // Getter for Insurance Type
    // get insuranceType(): string {
    //   return this.partData?.ASSET_INSURANCE_TYPE_s || '';
    // }
  
    // // Setter for Insurance Type
    // set insuranceType(value: string) {
    //   if (this.partData) {
    //     this.partData.ASSET_INSURANCE_TYPE_s = value;
    //   }
    // }
  
    // Getter for Valid Till
    get validTill(): string {
      return this.partData?.ASSET_INSURANCE_VALID_DT_dt || '';
    }
  
    // Setter for Valid Till
    set validTill(value: string) {
      if (this.partData) {
        this.partData.ASSET_INSURANCE_VALID_DT_dt = value;
      }
    }
    
  

    public formatDate(date: NgbDateStruct): string {
      const pad = (num: number) => (num < 10 ? '0' + num : num);
      return `${date.year}-${pad(date.month)}-${pad(date.day)}`;
    }

    transactionDate: NgbDateStruct; // Keep this as NgbDateStruct
    originalTransactionDate: NgbDateStruct; // Keep this as NgbDateStruct
    formattedTransactionDate: string;
    private initializeTransactionDate() {
      if (this.partData?.ASSET_INSURANCE_VALID_DT_dt) {
        const dateParts = this.partData.ASSET_INSURANCE_VALID_DT_dt.split('T')[0].split('-');
        this.transactionDate = {
          year: +dateParts[0],
          month: +dateParts[1],
          day: +dateParts[2]
        };
        this.originalTransactionDate = { ...this.transactionDate }; // Store original date as NgbDateStruct
        this.formattedTransactionDate = this.formatTransactionDate(this.transactionDate); // Format for payload
      } else {
        this.transactionDate = null; // Reset if no date is available
        this.originalTransactionDate = null;
        this.formattedTransactionDate = null; // Reset formatted date if no date is available
      }
    }

    isEditingDate: boolean = false;
    editDate() {
      this.isEditingDate = true;
      this.initializeTransactionDate(); // Ensure transactionDate is set to the current value
    }
  
    saveDate() {
      this.formattedTransactionDate = this.formatTransactionDate(this.transactionDate); // Update formatted date
      this.partData.ASSET_INSURANCE_VALID_DT_dt = this.formattedTransactionDate; // Update partData with new formatted date
      this.originalTransactionDate = { ...this.transactionDate }; // Update original date as NgbDateStruct
      this.isEditingDate = false; // Exit editing mode
    }

    // Call this method whenever partData changes
    public updatePartData(newPartData: any) {
      this.partData = newPartData;
      this.initializeTransactionDate(); // Update transactionDate when partData changes
    }
  
    // public formatTransactionDate(date: NgbDateStruct): string {
    //   const formattedDay = date?.day < 10 ? `0${date?.day}` : date?.day.toString();
    //   const formattedMonth = date?.month < 10 ? `0${date?.month}` : date?.month.toString();
  
    //   // Get the current date and time
    //   const currentDateTime = new Date();
  
    //   // Convert the current time to the UTC+5:30 time zone
    //   const timezoneOffset = 330; // 5 hours and 30 minutes in minutes
    //   const offsetMilliseconds = timezoneOffset * 60 * 1000;
    //   const utc5_30DateTime = new Date(currentDateTime.getTime() + offsetMilliseconds);
  
    //   // Format the time part
    //   const formattedTime = utc5_30DateTime.toISOString().slice(11, 23);
    //   const formattedTime1 = "T00:00:00Z";
    //   // Construct the final date-time string
    //   const formattedDate = `${date?.year}-${formattedMonth}-${formattedDay}${formattedTime1}`;
    //   // const formattedDate = `${date?.year}-${formattedMonth}-${formattedDay}${formattedTime1}`;
    //   return formattedDate;
    // }

    public formatTransactionDate(date: NgbDateStruct): string {
      if (!date) return null; // Return null if date is not defined
  
      const formattedDay = date.day < 10 ? `0${date.day}` : date.day.toString();
      const formattedMonth = date.month < 10 ? `0${date.month}` : date.month.toString();
      const formattedTime1 = "T00:00:00Z"; // Fixed time part
      // const formattedDate = `${date.year}-${formattedMonth}-${formattedDay}${formattedTime1}`;
      const formattedDate = `${formattedDay}/${formattedMonth}/${date.year}`;
      return formattedDate;
    }
  


    isEditing: boolean = false;
    editCompanyName() {
      this.isEditing = true;
      this.insuranceCompanyName = this.partData.INSURENCE_COMPONY_NAME_s; // Prefill input with existing name
    }
  
    // Method to save the changes (you can implement your logic here)
    saveCompanyName() {
      this.partData.INSURENCE_COMPONY_NAME_s = this.insuranceCompanyName; // Update partData with new name
      this.originalCompanyName = this.insuranceCompanyName; // Update original value
      this.isEditing = false; // Exit editing mode
  }
  isImageModalOpen = false;

  // Method to open the image modal
  openImageModal() {
    this.isImageModalOpen = true;
  }

  // Method to close the image modal
  closeImageModal() {
    this.isImageModalOpen = false;
  }

}
