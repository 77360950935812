<ng-container>
  <!-- Filter -->
  <ng-container *ngIf="isFilterable" [matColumnDef]="this.rowActionIcon">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="filter" />
    </mat-form-field>
  </ng-container>
  <div class="guru-list-table">
    <!-- Table -->
    <table
      mat-table
      [dataSource]="tableDataSource"
      class="full-width-table tb-table"
      matSort
      (matSortChange)="sortTable($event)"
    >
      <!-- action column -->
      <ng-container
        *ngIf="rowActionIcon?.length"
        [matColumnDef]="rowActionIcon"
      >
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" [id]="rowActionIcon">
          <button mat-button>
            <mat-icon>{{ rowActionIcon }}</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container
        *ngFor="let tableColumn of tableColumns"
        [matColumnDef]="tableColumn.name"
      >
        <!-- if sortable column header -->
        <ng-container *ngIf="tableColumn.isSortable; else notSortable">
          <th
            mat-header-cell
            *matHeaderCellDef
            [mat-sort-header]="tableColumn.name"
            [arrowPosition]="
              tableColumn.position === 'right' ? 'before' : 'after'
            "
          >
            {{ tableColumn.name }}
            <mat-icon 
            *ngIf="tableColumn.name === 'JC Status'"
            matTooltip="Click on i button to know more" 
            style="cursor: pointer; margin-left: 4px; color: darkblue;"
            (click)="showClaimStatusInfo('JC Status Info', $event)">
            info
            </mat-icon>
          </th>
        </ng-container>
        <!-- else not sortable -->
        <ng-template #notSortable>
          <th
            mat-header-cell
            *matHeaderCellDef
            [class.text-right]="tableColumn.position == 'right'"
          >
            {{ tableColumn.name }}
          </th>
          <mat-icon 
            *ngIf="tableColumn.name === 'JC Status'"
            matTooltip="Failure Problem. Flow will go like this: 1) Flow A. 2) Flow B. 3) Flow C" 
            style="cursor: pointer; margin-left: 4px;"
            (click)="showClaimStatusInfo('JC Status Info', $event)">
            info
            </mat-icon>
        </ng-template>
        <!--****************   Without popups code starts here ******************* -->
        <!-- column data -->
        <!-- <td
        mat-cell
        *matCellDef="let element"
        [class.text-right]="tableColumn.position == 'right'"


        > -->
        <!-- <div
          class="tb-column-item"
          [innerHTML]="element | dataPropertyGetter: tableColumn.dataKey"
        ></div>
        </td>
        </ng-container>
        <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="tb-head-row"
        ></tr> -->
        <!-- <tr
        class="tb-item-row active-guru"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        ></tr>
        </table>
        </div> -->

        <!--****************   Without popups code end here ******************* -->

        <!--****************   For payment update and job card detail popups starts here ******************* -->

        <!-- column data -->
        <td
          mat-cell
          *matCellDef="let element"
          [class.text-right]="tableColumn.position == 'right'"

          (click)="tableColumn.name === 'JC Status' && element.jcstatus === 'Fail' ? openfailureModal(element) : null"
          (click)="(tableColumn.name === 'Claim Status' && componentName === 'job-card-tgp-fitment' && role === 'DEALER' && element.claimstatus === 'Settled' ) ? onClaimClick(element.jobID, $event) : null"
          (click)="tableColumn.name === 'Job card Id' && componentName === 'job-card-tgp-fitment' && role === 'DEALER' && element.srType !== 'Paid Service' && element.srType !== 'Breakdown' ? onJobIdClick(element.jobID, $event): null"

          [ngStyle]="{
            'color':
              (tableColumn.name === 'Job card Id' && componentName === 'job-card-tgp-fitment' && (element.srType === 'Paid Service' || element.srType === 'Breakdown' || element.srType === '')) ? 'Default' :
              (tableColumn.name === 'Job card Id' && componentName === 'job-card-tgp-fitment' && role === 'DEALER') ? 'blue' :
              (tableColumn.name === 'JC Status' && componentName === 'job-card-tgp-fitment' && element.jcstatus === 'Fail') ? 'red' :
              (tableColumn.name === 'Claim Status' && componentName === 'job-card-tgp-fitment' && role === 'DEALER' && element.claimstatus === 'Settled') ? 'blue' :
              (element | dataPropertyGetter: tableColumn.dataKey) === 'True' ? 'Default' : 
              (element | dataPropertyGetter: tableColumn.dataKey) === 'False' ? 'blue' : '',
            'cursor':
              (tableColumn.name === 'Job card Id' && componentName === 'job-card-tgp-fitment' && (element.srType === 'Paid Service' || element.srType === 'Breakdown' || element.srType === '')) ? 'default' :
              (tableColumn.name === 'Job card Id' || tableColumn.name === 'Claim Status' || element.claimstatus === 'Settled' || tableColumn.name === 'JC Status') && role === 'DEALER' ? 'pointer' : 'default'
          }"
        >
          <div
            class="tb-column-item"
            [innerHTML]="tableColumn.dataKey === 'action' ? '' : (element | dataPropertyGetter: tableColumn.dataKey)"
          ></div>
          <!-- Update payment for Dealer -->
          <ng-container *ngIf="tableColumn.dataKey === 'action' && tableColumn.name === 'Action' && componentName === 'job-card-tgp-fitment' && role === 'DEALER'">
            <span *ngIf="(element | dataPropertyGetter: tableColumn.dataKey) === 'Paid'; else paymentUpdateClick" style="pointer-events: none; cursor: not-allowed;">
              Paid
            </span>
              <ng-template #paymentUpdateClick>
                <span *ngIf="element.claimstatus === 'Settled'" (click)="onPaymentUpdate(element.jobID)" style="cursor: pointer; color: blue;">
                  Update Payment
                </span>
                <ng-template #notSettled>
                  <span style="text-align: center; color: black;">-</span>
                </ng-template>
              </ng-template>
            
          </ng-container>

          <!-- Update payment for admin -->
          <ng-container *ngIf="tableColumn.dataKey === 'action' && tableColumn.name === 'Action' && componentName === 'job-card-tgp-fitment' && role === 'SuperAdmin'">
            <span *ngIf="(element | dataPropertyGetter: tableColumn.dataKey) === 'Paid'; else paymentUpdateClicks" style="pointer-events: none; cursor: not-allowed;">
              Paid
            </span>
              <ng-template #paymentUpdateClicks>
                <span style="color: maroon; pointer-events: none; cursor: not-allowed;">
                  Unpaid
                </span>
              </ng-template>
            
          </ng-container>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="tb-head-row"
      ></tr>
      <tr
        class="tb-item-row active-guru"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>

<!--****************   For payment update and job card detail popups ends here ******************* -->


  <ng-container *ngIf="showNodata">
    <div class="no-item-found">No record found</div>
  </ng-container>
  <!-- Pagination -->
  <!-- <mat-paginator *ngIf="isPageable" [pageSizeOptions]="paginationSizes" [pageSize]="defaultPageSize"
        showFirstLastButtons class="custom-paginator">
    </mat-paginator> -->
  <ng-container *ngIf="isDataReady">
    <mat-paginator
      appPagination
      *ngIf="isPageable"
      showFirstLastButtons
      [length]="testPaginator.length"
      [pageSize]="testPaginator.pageSize"
      [pageIndex]="testPaginator.pageIndex"
      [hidePageSize]="true"
      (page)="pageChanged($event)"
      class="custom-paginator"
    >
    </mat-paginator>
  </ng-container>
</ng-container>
<ng-template #partSummaryPopup let-d="dismiss">
  <app-job-card-details 
      [partData]="partSummary" 
      [jobId]="selectedJobId" 
      [fetchData]="fetchrate"
      [selectedRateListAPI]="selectedRateListName">
  </app-job-card-details>
</ng-template>
<ng-template #invoicePaymentListPopup let-d="dismiss">
  <app-invoice-payment-list [jobIds]="clickedJobId"></app-invoice-payment-list>
</ng-template>
<ng-template #claimStatusPopup let-d="dismiss">
  <app-claim-status-details [claimData]="claimSummary"></app-claim-status-details>
</ng-template>
<ngx-spinner></ngx-spinner>

<!-- Modal Structure -->
<div class="modal fade" tabindex="-1" role="dialog" *ngIf="isImageModalOpen" aria-labelledby="imageModalLabel" [ngClass]="{'show': isImageModalOpen}" [ngStyle]="{'display': isImageModalOpen ? 'block' : 'none'}">
  <div class="modal-dialog modal-lg modal-bottom">
    <div class="modal-content" style="margin-bottom: 100px;">
      <div class="modal-header" style="background-color: rgb(192, 215, 255);">
        <h3 class="modal-title" id="imageModalLabel"><strong>Failure Status Details</strong></h3>
        <button type="button" class="btn-close" aria-label="Close" (click)="closefailureModal()"></button>
      </div>

      <div class="modal-body" style="margin: 10px 0 40px 0;">
        <div class="container">

          <!-- Failure Reason Section -->
          <strong>Failure Reason:</strong>
          <div style="margin: 15px 0 20px 0; padding: 10px; border: 1px solid #ccc; background-color: #f9f9f9; border-radius: 8px;">
            <p style="font-size: 15px; line-height: 1.6; font-weight: 500;">
              {{ selectedElement?.failure_reason || 'N/A' }}
            </p>
          </div>

          <!-- Step Number Failure Section -->
          <strong style="margin-top: 25px;">Step Number Failure:</strong>
          <h4 style="margin-top: 5px;">{{ selectedElement?.step_number || 'N/A' }}</h4>

          <!-- Circle Status Representation -->
          <div style="display: flex; justify-content: center; align-items: center; position: relative; margin-top: 20px;">
            <!-- Circle Loop -->
            <ng-container *ngFor="let step of [1, 2, 3, 4, 5]; let i = index">
              <div style="display: flex; flex-direction: column; align-items: center; position: relative; margin-right: 30px;">
                <!-- Step label (text above the circle) -->
                <span [innerHTML]="getStepLabel(step)" style="color: black; font-weight: bold; margin-bottom: 5px;"></span>

                <!-- Circle with step number inside -->
                <div [ngStyle]="{
                  'width': '70px',
                  'height': '70px',
                  'background-color': getCircleColor(i + 1),
                  'border-radius': '50%',
                  'display': 'flex',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'margin-top': '20px'
                }">
                  <span style="color: white; font-weight: bold;">{{ step }}</span>
                </div>

                <!-- Horizontal line between circles -->
                <div *ngIf="i < 4" 
                [ngStyle]="{
                  'position': 'absolute', 
                  'width': '40px', 
                  'height': '3px', 
                  'margin-top': '30px', 
                  'background-color': getLineColor(i + 1),
                  'left': '100%', 
                  'top': '50%', 
                  'transform': 'translateY(-50%)'
                }">
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div> 
  </div> 
</div>
